<template>
    <div class="content pt-0">
        <section class="tyre-bg">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-3">
                        <div class="title-small mb-4 text-center">
                            Új jelszó megadása
                        </div>
                        <p class="text-center">
                            Kérlek add meg új jelszavadat!
                        </p>
                        <form v-on:submit.prevent="doReset">
                            <div class="my-4">
                                <label>Jelszó</label>
                                <input
                                    type="password"
                                    name="password"
                                    v-model="model.password"
                                    class="form-control form-underlined"
                                />
                            </div>
                            <div class="my-4">
                                <label>Jelszó újra</label>
                                <input
                                    type="password"
                                    name="password_again"
                                    v-model="model.password_confirmation"
                                    class="form-control form-underlined"
                                />
                            </div>

                            <button type="submit" class="btn btn-primary w-100">
                                Jelszó visszaállítása
                            </button>

                            <div class="row my-4">
                                <div class="col-md-6">
                                    <router-link :to="{ name: 'Registration' }"
                                        >Vissza a regisztrációhoz</router-link
                                    >
                                </div>
                                <div class="col-md-6 text-end">
                                    <router-link :to="{ name: 'Login' }"
                                        >Bejelentkezés</router-link
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "LostPassword",
    data() {
        return {
            model: {
                password: null,
                password_confirmation: null
            }
        };
    },
    mounted() {
        this.onReset();
    },
    watch: {
        "$route.query": {
            handler: function() {
                this.onReset();
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return process.env.NODE_ENV === "production"
                ? `${process.env.VUE_APP_API_DIOS_URL}/reset-password`
                : "/reset-password";
        }
    },
    methods: {
        onReset() {
            this.model.token = this.$route.params.token;
            this.model.email = this.$route.query.email.replace(" ", "+");
        },
        doReset() {
            const self = this;
            ApiService.post(this.url, this.model).then(() => {
                this.model = {
                    password: null,
                    password_confirmation: null
                };

                this.toastMessage(
                    self.$translate.t("Sikeres jelszó változtatás!"),
                    self.$translate.t(
                        "Most már be tudsz jelentkezni az új jelszavaddal."
                    )
                );

                self.$router.push({ name: "Login" });
            });
        }
    }
};
</script>

<style scoped></style>
